@charset "UTF-8";
/* Colors */
html {
  font-size: 100%;
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #f5f5f5;
  margin-top: 0;
}

.sectionTitle {
  text-transform: uppercase;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 10px;
  color: #f5c14c;
  margin-bottom: 1rem;
  border-bottom: 5px #f5c14c solid;
}

#wtf {
  display: none;
}

@media (max-width: 308px) {
  body {
    margin: 0;
  }
  .contactInformation {
    text-align: center;
  }
  ul {
    padding: 0 0 0 0;
  }
  .rightContent {
    display: none;
  }
  .leftContent {
    display: none;
  }
  #wtf {
    display: block;
  }
  #wtf img {
    width: 100%;
  }
  #wtf span {
    font-family: "Courier New", Courier, monospace;
    font-weight: bolder;
    color: white;
    letter-spacing: 2px;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
  }
}
@media (min-width: 309px) and (max-width: 319px) {
  body {
    margin: 0;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  body {
    margin: 0;
  }
  .sectionTitle {
    font-size: 1.1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  body {
    margin: 0;
  }
  .sectionTitle {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .sectionTitle {
    font-size: 1.2rem;
  }
}
.grid-container {
  /* Default values */
  max-width: 1000px;
  margin: auto;
  display: grid;
  width: 80vw;
  box-shadow: 0 0 20px lightgray;
  background-color: #f5f5f5;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "header header header header" "leftContent leftContent rightContent rightContent" "footerContent footerContent footerContent footerContent";
  /* Media queries */
  /* Small smartphones */
}
@media (max-width: 319px) {
  .grid-container {
    width: 100vw;
    margin: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr 1fr 0.1fr;
    grid-template-areas: "header" "leftContent" "rightContent" "footerContent";
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .grid-container {
    width: 100vw;
    margin: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.7fr 1fr 0.1fr;
    grid-template-areas: "header" "leftContent" "rightContent" "footerContent";
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .grid-container {
    width: 100vw;
    margin: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.1fr 1fr 0.1fr;
    grid-template-areas: "header header" "leftContent rightContent" "footerContent footerContent";
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .grid-container {
    width: 90vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.1fr 1fr 0.1fr;
    grid-template-areas: "header header" "leftContent rightContent" "footerContent footerContent";
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .grid-container {
    width: 90vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.1fr 1fr 0.1fr;
    grid-template-areas: "header header" "leftContent rightContent" "footerContent footerContent";
  }
}
@media (min-width: 1281px) {
  .grid-container {
    width: 90vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.1fr 1fr 0.1fr;
    grid-template-areas: "header header" "leftContent rightContent" "footerContent footerContent";
  }
}

.header {
  background: url(../img/bg.webp) repeat;
  background-size: 180px 149px;
  display: grid;
  grid-area: header;
  background-color: #262335;
  box-shadow: 0 0 5px gray;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 0.2fr;
  grid-template-areas: "nameAndPosition nameAndPosition nameAndPosition profilePicture" "description description description profilePicture" "contactInformation contactInformation contactInformation contactInformation";
  /* ipad y 9t horizontal */
  /* 15" screen */
  /* Bigger than 15" screen */
}
.header #readMore {
  width: 2rem;
}
.header *:focus {
  outline: none;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.header details {
  cursor: pointer;
}
.header details summary {
  display: flex;
  align-items: stretch;
  justify-content: left;
  margin-top: 0.5rem;
}
.header details summary span:last-child {
  font-weight: bold;
  margin-left: 0.5rem;
  padding-top: 0.2rem;
}
.header details summary .icon {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 2s;
  animation-iteration-count: 3;
}
.header details summary::-webkit-details-marker {
  display: none;
}
.header details p {
  cursor: default;
}
.header .description {
  padding: 0 0 0 0.5rem;
  text-align: justify;
  margin: 0 10px 10px 10px;
  color: #fff;
  grid-area: description;
}
.header .description details p {
  font-size: 1rem;
  margin-right: 0.7rem;
}
.header .description details p .icon {
  width: 1rem;
}
@keyframes sweep {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}
.header .nameAndPosition {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 0.2fr;
  grid-template-areas: "name" "position";
  grid-area: nameAndPosition;
}
.header .nameAndPosition .name {
  grid-area: name;
  color: white;
  letter-spacing: 1px;
  font-weight: bolder;
  text-shadow: 0 0 5px #f92aad;
}
.header .nameAndPosition .position {
  color: #f5c14c;
}
.header .profilePicture {
  padding: 1rem 0 0 0;
  text-align: center;
}
.header #meAndMySelf {
  width: 80%;
  border: 5px solid #f5c14c;
  border-radius: 50%;
}
.header .contactInformation {
  text-align: center;
  grid-area: contactInformation;
  background-color: #241b2f;
}
.header .contactInformation li {
  color: rgb(185, 196, 206);
  display: inline;
  margin-right: 2rem;
}
.header .contactInformation li .icon {
  --size: 1rem;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  -webkit-mask-size: cover;
  mask-size: cover;
}
.header .contactInformation li .email {
  --size: 2rem;
  -webkit-mask-image: url(../img/email.svg);
  mask-image: url(../img/email.svg);
  background-color: #626068;
}
.header .contactInformation li .email:hover {
  transition: all 0.5s;
  background-color: #d1d0d2;
}
.header .contactInformation li .linkedin {
  --size: 2rem;
  -webkit-mask-image: url(../img/linkedin.svg);
  mask-image: url(../img/linkedin.svg);
  background-color: #626068;
}
.header .contactInformation li .linkedin:hover {
  transition: all 0.5s;
  background-color: #d1d0d2;
}
.header .contactInformation li .lockpad {
  --size: 2rem;
  -webkit-mask-image: url(../img/lockpad.svg);
  mask-image: url(../img/lockpad.svg);
  background-color: #626068;
}
.header .contactInformation li .lockpad:hover {
  transition: all 0.5s;
  background-color: #d1d0d2;
}
.header .contactInformation li .twitter {
  --size: 2rem;
  -webkit-mask-image: url(../img/twitter.svg);
  mask-image: url(../img/twitter.svg);
  background-color: #626068;
}
.header .contactInformation li .twitter:hover {
  transition: all 0.5s;
  background-color: #d1d0d2;
}
@media (max-width: 308px) {
  .header .name {
    font-size: 1.2rem !important;
    text-align: center;
  }
  .header .description {
    display: none;
  }
  .header details summary {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-top: 0.5rem;
  }
  .header details summary span:last-child {
    font-weight: bold;
    margin-left: 0.5rem;
    padding-top: 0.2rem;
  }
  .header details summary::-webkit-details-marker {
    display: none;
  }
  .header .contactInformation {
    text-align: center;
    grid-area: contactInformation;
    background-color: #241b2f;
  }
  .header .contactInformation li .email {
    --size: 1rem;
    -webkit-mask-image: url(../img/email.svg);
    mask-image: url(../img/email.svg);
    background-color: #d1d0d2;
  }
  .header .contactInformation li .linkedin {
    --size: 1rem;
    -webkit-mask-image: url(../img/linkedin.svg);
    mask-image: url(../img/linkedin.svg);
    background-color: #d1d0d2;
  }
  .header .contactInformation li .lockpad {
    --size: 1rem;
    -webkit-mask-image: url(../img/lockpad.svg);
    mask-image: url(../img/lockpad.svg);
    background-color: #d1d0d2;
  }
  .header .contactInformation li .twitter {
    --size: 1rem;
    -webkit-mask-image: url(../img/twitter.svg);
    mask-image: url(../img/twitter.svg);
    background-color: #d1d0d2;
  }
}
@media (max-width: 319px) {
  .header {
    grid-template-columns: 1fr 0.35fr;
    grid-template-rows: 0.1fr 0.5fr 0.1fr;
    grid-template-areas: "nameAndPosition nameAndPosition" "description description" "contactInformation contactInformation";
  }
  .header .profilePicture {
    display: none;
  }
  .header .nameAndPosition {
    margin: 1rem 0 0 1rem;
    padding-right: 1rem;
  }
  .header .name {
    font-size: 2rem;
    white-space: nowrap;
  }
  .header .position {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .header {
    grid-template-columns: 1fr 0.35fr;
    grid-template-rows: 0.1fr 0.5fr 0.1fr;
    grid-template-areas: "nameAndPosition profilePicture" "description description" "contactInformation contactInformation";
  }
  .header .nameAndPosition {
    margin: 1rem 0 0 1rem;
    padding-right: 1rem;
  }
  .header .name {
    font-size: 1.3rem;
    white-space: nowrap;
  }
  .header .position {
    font-size: 1.1rem;
  }
  .header .profilePicture {
    width: 85%;
  }
  .header #meAndMySelf {
    width: inherit;
    border: 3px solid #f5c14c;
  }
  .header .contactInformation li .icon {
    background-color: #d1d0d2;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .header {
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr 0fr 0.1fr;
    grid-template-areas: "nameAndPosition nameAndPosition nameAndPosition profilePicture" "description description description description" "contactInformation contactInformation contactInformation contactInformation";
  }
  .header .nameAndPosition {
    margin: 1rem 0 0 1rem;
    padding-right: 1rem;
  }
  .header .name {
    font-size: 2rem;
    white-space: nowrap;
  }
  .header .position {
    font-size: 1.1rem;
  }
  .header .profilePicture {
    text-align: left;
  }
  .header #meAndMySelf {
    width: 80%;
    border: 4px solid #f5c14c;
    border-radius: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr 0fr 0.1fr;
    grid-template-areas: "nameAndPosition nameAndPosition nameAndPosition profilePicture" "description description description description" "contactInformation contactInformation contactInformation contactInformation";
  }
  .header .contactInformation li .icon {
    background-color: #d1d0d2;
  }
  .header .nameAndPosition {
    margin: 1rem 0 0 1rem;
    padding-right: 1rem;
  }
  .header .name {
    font-size: 2rem;
    white-space: nowrap;
  }
  .header .position {
    font-size: 1.1rem;
  }
  .header .profilePicture {
    text-align: left;
  }
  .header #meAndMySelf {
    width: 80%;
    border: 4px solid #f5c14c;
    border-radius: 50%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .header {
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr 0fr 0.1fr;
    grid-template-areas: "nameAndPosition nameAndPosition nameAndPosition profilePicture" "description description description description" "contactInformation contactInformation contactInformation contactInformation";
  }
  .header .nameAndPosition {
    margin: 1rem 0 0 1rem;
    padding-right: 1rem;
  }
  .header .name {
    font-size: 2.2rem;
    white-space: nowrap;
  }
  .header .position {
    font-size: 1.3rem;
  }
  .header .profilePicture {
    text-align: left;
  }
  .header #meAndMySelf {
    width: 80%;
    border: 4px solid #f5c14c;
    border-radius: 50%;
  }
}
@media (min-width: 1281px) {
  .header {
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr 0fr 0.1fr;
    grid-template-areas: "nameAndPosition nameAndPosition nameAndPosition profilePicture" "description description description description" "contactInformation contactInformation contactInformation contactInformation";
  }
  .header .nameAndPosition {
    margin: 1rem 0 0 1rem;
    padding-right: 1rem;
  }
  .header .name {
    font-size: 2.2rem;
    white-space: nowrap;
  }
  .header .position {
    font-size: 1.3rem;
  }
  .header .profilePicture {
    text-align: left;
  }
  .header #meAndMySelf {
    width: 80%;
    border: 4px solid #f5c14c;
    border-radius: 50%;
  }
}
@media print {
  .header .header::before {
    content: "Visita mi sitio moiseslodeiro.es para más información / Visit my website moiseslodeiro.es for more information";
  }
  .header a[href]:after {
    content: " (" attr(href) ")";
  }
}

.leftContent {
  grid-area: leftContent;
  padding: 1rem;
}
.leftContent .place {
  font-weight: bold;
  padding-bottom: 0.3rem;
}
.leftContent .job {
  font-style: italic;
}
.leftContent .jobDescription {
  padding-bottom: 0.5rem;
}
.leftContent .jobDescription li {
  padding-bottom: 0.2rem;
}
@media (min-width: 320px) and (max-width: 480px) {
  .leftContent {
    padding: 1rem 1rem 0 1rem;
  }
  .leftContent .jobDescription {
    display: none;
  }
  .leftContent .section .element {
    margin-bottom: 1rem;
  }
}

.rightContent {
  grid-area: rightContent;
  padding: 1rem;
}
.rightContent .course {
  margin-bottom: 0.5rem;
}
.rightContent .course .name {
  font-weight: 400;
}
.rightContent .course .coursePlace {
  font-style: italic;
  color: #f5c14c;
}
.rightContent .section {
  margin-bottom: 1rem;
}
.rightContent .section .sectionTitle i {
  text-transform: lowercase;
  color: #626068;
  font-size: 0.8rem;
}
.rightContent .section .skills {
  display: flex;
  flex-flow: row wrap;
}
.rightContent .section .skills .skill {
  flex: 1 auto;
  text-align: center;
  display: block;
  background-color: #f5c14c;
  text-decoration: none;
  padding: 0.5rem;
  margin: 0.1rem;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
}
.rightContent .section .element {
  display: flex;
  align-items: stretch;
  font-weight: bold;
}
.rightContent .section .element .bullet {
  height: 1rem;
  width: 1rem;
  background-color: #f5c14c;
  border-radius: 50%;
  display: inline-block;
}
.rightContent .section .rating {
  margin-left: auto;
}
.rightContent .section ul {
  padding-left: 0;
}
.rightContent .section li {
  list-style: none;
  align-items: stretch;
  justify-content: left;
  margin-bottom: 0.3rem;
}
.rightContent .section li span {
  margin-left: 0.5rem;
  font-weight: bold;
}
.rightContent .section li div:last-child {
  margin: 0 0 0.5rem 1.6rem;
}
.rightContent .section .icon {
  --size: 1.1rem;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  -webkit-mask-size: cover;
  mask-size: cover;
}
.rightContent .section .cybersecurity {
  -webkit-mask-image: url(../img/shield.svg);
  mask-image: url(../img/shield.svg);
  background-color: #626068;
}
.rightContent .section .develop {
  -webkit-mask-image: url(../img/code.svg);
  mask-image: url(../img/code.svg);
  background-color: #626068;
}
.rightContent .section .ai {
  -webkit-mask-image: url(../img/ai.svg);
  mask-image: url(../img/ai.svg);
  background-color: #626068;
}
.rightContent .section .sysadmin {
  -webkit-mask-image: url(../img/syadmin.svg);
  mask-image: url(../img/syadmin.svg);
  background-color: #626068;
}
@media (min-width: 320px) and (max-width: 480px) {
  .rightContent li div:last-child {
    display: none;
  }
}

.footerContent {
  text-align: center;
  grid-area: footerContent;
  background-color: #262335;
  color: #fff;
  font-size: 0.9rem;
  padding: 1rem;
  line-height: 1.2rem;
}
.footerContent .icon {
  --size: 0.9rem;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(../img/printer.svg);
  mask-image: url(../img/printer.svg);
  background-color: #f5f5f5;
}
.footerContent a {
  cursor: pointer;
  text-decoration: none;
  color: #03edf9;
}

.noDisplay {
  display: none;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  @page {
    margin: 0.9cm;
  }
  .profilePicture {
    display: none;
  }
  li {
    orphans: 1;
    widows: 1;
  }
  .noDisplay {
    display: block;
  }
  .name {
    display: inline-block;
  }
  .display {
    display: none;
  }
  .icon {
    display: none !important;
  }
  body {
    background: #fff;
  }
  .grid-container {
    box-shadow: none;
    background-color: white;
  }
  .nameAndPosition .name {
    display: flex;
    color: black !important;
    text-shadow: none !important;
    margin-bottom: 1rem;
  }
  .nameAndPosition .position {
    color: black !important;
  }
  .header {
    background: none;
    box-shadow: none;
  }
  .header .position {
    display: none;
  }
  .header .contactInformation {
    display: none;
  }
  .description {
    display: none;
  }
  .section .sectionTitle {
    color: black;
    border-bottom: 2px solid black !important;
  }
  .section .element {
    page-break-before: avoid;
    margin-bottom: 0.5rem;
  }
  .section .course {
    margin-bottom: 0.5rem;
  }
  .section .course .coursePlace {
    color: black !important;
  }
  .section .skills .skill {
    margin: 0 !important;
    padding: 0 !important;
    background-color: white;
    color: black !important;
  }
  #meAndMySelf {
    border: 2px solid black !important;
  }
}